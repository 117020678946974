export const mobileLogin_MANXIN = {
    sdk_m_getVerificationCode: ({ phoneNum, validcode }, callback) => {},
    sdk_m_phoneNumLogin: ({ phoneNum, verificationCode }, callback) => {},
};

const loadPassportmanxinjs = (call) => {
    const passportmanxinjs = document.createElement('script');
    passportmanxinjs.type = 'text/javascript';
    passportmanxinjs.src = `${document.location.protocol}//j1.58cdn.com.cn/git/teg-app-fe/passport-sdk-m/static/js/sdk_m.js`;
    passportmanxinjs.onload = () => {
        call();
        const _mobileLogin_MANXIN = new window.sdk_m_init({
            type: 'phoneNumLogin', //类型
            source: '58-yigouh5-m', //源
            path: window.location.href, //登录成功后跳转的目标地址
            domain: 'yigou.58manxin.com',
        });
        mobileLogin_MANXIN.sdk_m_getVerificationCode = window.sdk_m_getVerificationCode.bind(
            _mobileLogin_MANXIN,
        );
        mobileLogin_MANXIN.sdk_m_phoneNumLogin = window.sdk_m_phoneNumLogin.bind(
            _mobileLogin_MANXIN,
        );
    };
    const s = document.getElementsByTagName('script')[0];
    s.parentNode && s.parentNode.insertBefore(passportmanxinjs, s);
};
export default loadPassportmanxinjs;
