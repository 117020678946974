/*
 * @Description: 底部导航栏
 * @Author: lihaonan04
 * @LastEditors: lihaonan04
 * @Date: 2020-02-18 11:01:03
 * @LastEditTime: 2020-02-25 15:03:00
 */
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import home_icon from './img/home_icon.png';
import home_gray_icon from './img/home_gray_icon.png';
import my_icon from './img/my_icon.png';
import my_gray_icon from './img/my_gray_icon.png';
import './index.scss';

interface Bar {
    name: string;
    path: string;
    alt: string;
    grayAlt: string;
    icon: string;
    grayIcon: string;
}

const barList: Array<Bar> = [
    {
        name: '首页',
        path: '/home',
        alt: 'home_icon',
        grayAlt: 'home_gray_icon',
        icon: home_icon,
        grayIcon: home_gray_icon,
    },
    {
        name: '我的',
        path: '/my',
        alt: 'my_icon',
        grayAlt: 'my_gray_icon',
        icon: my_icon,
        grayIcon: my_gray_icon,
    },
];

const FooterBar = React.memo(function (props: RouteComponentProps) {
    const { pathname } = props.location;

    return (
        <div className="footer-bar">
            <ul className="footer-bar-list">
                {barList.map((bar) => (
                    <li
                        key={bar.path}
                        className="footer-bar-item"
                        onClick={() => {
                            if (pathname === bar.path) return;
                            props.history.push(bar.path);
                        }}
                    >
                        {/* icon */}
                        <img
                            className="footer-bar-item-icon"
                            src={pathname === bar.path ? bar.icon : bar.grayIcon}
                            alt={pathname === bar.path ? bar.alt : bar.grayAlt}
                        />
                        {/* 导航名称 */}
                        <span
                            className={`footer-bar-item-name${
                                pathname === bar.path ? '' : ' gray'
                            }`}
                        >
                            {bar.name}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
});

export default withRouter(FooterBar);
