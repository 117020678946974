import React from 'react';
// 异步加载文件 参考文档 https://webpack.js.org/guides/code-splitting/#dynamic-imports
// 参数中的注释部分不建议删除，原因请看上述文档
const Login = () => import('./containers/Login/index' /* webpackChunkName:"Login" */);
const Home = () => import('./containers/Home/index' /* webpackChunkName:"Home" */);
const ProjectDetail = () =>
    import('./containers/ProjectDetail/index' /* webpackChunkName:"ProjectDetail" */);
const CouponBuy = () => import('./containers/CouponBuy/index' /* webpackChunkName:"CouponBuy" */);
const InfoService = () =>
    import('./containers/Terms/infoService' /* webpackChunkName:"InfoService" */);
const PayDetail = () => import('./containers/PayDetail/index' /* webpackChunkName:"PayDetail" */);
const My = () => import('./containers/My/index' /* webpackChunkName:"My" */);
const RealName = () => import('./containers/RealName/index' /* webpackChunkName:"RealName" */);
const PaySuccess = () =>
    import('./containers/PaySuccess/index' /* webpackChunkName:"PaySuccess" */);
const PayFail = () => import('./containers/PayFail/index' /* webpackChunkName:"PaySuccess" */);

const CouponDetail = () =>
    import('./containers/CouponDetail/index' /* webpackChunkName:"CouponDetail" */);

declare interface RouteItem {
    name: string;
    path: string;
    exact: boolean;
    async: boolean;
    component: React.ComponentType<any> | Function;
}

const routes: RouteItem[] = [
    /**
     * 登录注册
     */
    {
        name: 'login',
        path: '/login',
        component: Login,
        exact: true,
        async: true,
    },
    /**
     * 首页
     */
    {
        name: 'home',
        path: '/home',
        component: Home,
        exact: true,
        async: true,
    },
    /**
     * 项目详情页
     */
    {
        name: 'projectDetail',
        path: '/projectDetail',
        component: ProjectDetail,
        exact: true,
        async: true,
    },
    /**
     * 易购券详情
     */
    {
        name: 'couponBuy',
        path: '/couponBuy',
        component: CouponBuy,
        exact: true,
        async: true,
    },
    /**
     * 易购券详情58易购信息服务协议
     */
    {
        name: 'infoService',
        path: '/couponBuy/infoService',
        component: InfoService,
        exact: true,
        async: true,
    },
    /**
     * 预约成功（pos扫码支付）
     */
    {
        name: 'payDetail',
        path: '/pay/detail/:orderId',
        component: PayDetail,
        exact: true,
        async: true,
    },
    {
        name: 'my',
        path: '/my',
        component: My,
        exact: true,
        async: true,
    },
    {
        name: 'realname',
        path: '/realname',
        component: RealName,
        exact: true,
        async: true,
    },
    {
        name: 'paySuccess',
        path: '/pay/success/:orderId',
        component: PaySuccess,
        exact: true,
        async: true,
    },
    {
        name: 'payFail',
        path: '/pay/fail/:orderId',
        component: PayFail,
        exact: true,
        async: true,
    },
    {
        name: 'couponDetail',
        path: '/coupon/detail/:couponId',
        component: CouponDetail,
        exact: true,
        async: true,
    },
];

export default routes;
