//import base&&tool
import 'whatwg-fetch';
import 'scss_mixin/reset.scss'; //reset 样式
import 'tools/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import loadPassportjs from 'tools/loadPassportjs';
import loadPassportmanxinjs from 'tools/loadPassportmanxinjs';

import {
    HashRouter as Router,
    Route,
    Switch,
    Redirect,
    withRouter,
    RouteComponentProps,
} from 'react-router-dom';

// bundleLoader 异步加载路由页面
import BundleLoader from 'lm-bundle-loader';

// scrollToTop 路由切换时候页面默认滚动到顶部，可在此控件中配置白名单
import ScrollToTop from 'commons/ScrollToTop';
import FooterBar from 'commons/FooterBar';

import routes from './routes';

// 需要底部栏的页面地址
const footerBarList = ['/home', '/my'];

//主页面路由
// @ts-ignore
class MainRouterBase extends React.PureComponent<RouteComponentProps> {
    render() {
        const { pathname } = this.props.location;
        // 判断当前页面是否需要底部栏
        const isNeedFooterBar = footerBarList.indexOf(pathname) !== -1;

        return (
            <ScrollToTop>
                <main className={`main-container${isNeedFooterBar ? ' has-footer-bar' : ''}`}>
                    <Switch>
                        {routes.map((route) =>
                            route.async ? (
                                <Route
                                    path={route.path}
                                    exact={route.exact}
                                    key={route.name}
                                    render={(props: object) => BundleLoader(route.component, props)}
                                />
                            ) : (
                                    <Route
                                        path={route.path}
                                        exact={route.exact}
                                        key={route.name}
                                        component={route.component as React.ComponentType<any>}
                                    />
                                ),
                        )}
                        <Redirect to="/home" />
                    </Switch>
                    {isNeedFooterBar ? <FooterBar /> : null}
                </main>
            </ScrollToTop>
        );
    }
}
const MainRouter = withRouter(MainRouterBase);

const rootRender = () => {

    const rootElement = document.getElementById('root');

    ReactDOM.render(
        // @ts-ignore
        <Router>
            <MainRouter />
        </Router>,
        rootElement
    );

};


const HOST = window.location.host;
let reg = /\.58\.com/g;
let is58 = reg.test(HOST);
if (is58) {
    // h5 先加载passport.js 再拿到passport里面的方法
    loadPassportjs(() => {
        rootRender();
    });
} else {
    loadPassportmanxinjs(() => {
        rootRender();
    })
}



