export const mobileLogin = {
    getCode: ({ mobile, callback, validcode }) => {},
    freshValidateCode: () => {},
    loginCommit: ({ mobile, mobilecode, callback }) => {},
};

const loadPassportjs = (call) => {
    const passportjs = document.createElement('script');
    passportjs.type = 'text/javascript';
    passportjs.src = `${document.location.protocol}//j1.58cdn.com.cn/js/login/passportMobileLogin.js`;
    passportjs.onload = () => {
        call();
        const _mobileLogin = new window.MobileLogin({
            source: '58-fangyigou-m',
            path: window.location.href,
        });
        mobileLogin.getCode = _mobileLogin.getCode.bind(_mobileLogin);
        mobileLogin.freshValidateCode = _mobileLogin.freshValidateCode.bind(_mobileLogin);
        mobileLogin.loginCommit = _mobileLogin.loginCommit.bind(_mobileLogin);
    };
    const s = document.getElementsByTagName('script')[0];
    s.parentNode && s.parentNode.insertBefore(passportjs, s);
};
export default loadPassportjs;
